import { Typography, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { getAppTitle } from 'src/branding';
import { ContentArea } from 'src/components/ContentArea';
import { setDocumentTitle } from 'src/routes';

import { DesertSvg, ErrorSvg } from '../Svg';

import { i18n } from './GenericError.i18n';
import { useStyles } from './GenericError.styles';
import { GenericErrorProps, GenericErrorBaseProps } from './GenericError.types';

const ErrorBase = ({
    backgroundImage,
    title,
    subtitle,
    defaultMessage,
    message,
    showDefaultMessage,
}: GenericErrorBaseProps) => {
    const { classes } = useStyles();
    return (
        <Grid container justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item xs={12} className={classes.imageBlock}>
                {backgroundImage}
                <div className={classes.floatingTextBlock}>
                    <Typography variant="h1" className={classes.title}>
                        <FormattedMessage {...title} />
                    </Typography>
                    <Typography variant="h2" className={classes.subtitle}>
                        <FormattedMessage {...subtitle} />
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={classes.messageBlock}>
                {showDefaultMessage && (
                    <Typography variant="subtitle1">
                        <FormattedMessage {...defaultMessage} />
                    </Typography>
                )}
                {message && <Typography variant="subtitle1">{message}</Typography>}
            </Grid>
        </Grid>
    );
};

export const GenericError = ({ message, showDefaultMessage = true }: GenericErrorProps) => {
    const { classes } = useStyles();
    return (
        <ErrorBase
            backgroundImage={<ErrorSvg classes={{ root: classes.image }} />}
            title={i18n.title}
            subtitle={i18n.subtitle}
            defaultMessage={i18n.defaultMessage}
            message={message}
            showDefaultMessage={showDefaultMessage}
        />
    );
};

export const GenericNotFoundError = ({ message, showDefaultMessage = true }: GenericErrorProps) => {
    const intl = useIntl();
    setDocumentTitle([getAppTitle(), intl.formatMessage(i18n.pageTitle)]);
    return (
        <ErrorBase
            backgroundImage={<DesertSvg />}
            title={i18n.title404}
            subtitle={i18n.subtitle404}
            defaultMessage={i18n.defaultMessage404}
            message={message}
            showDefaultMessage={showDefaultMessage}
        />
    );
};

const GenericErrorComponent = () => <ContentArea content={<GenericNotFoundError />} />;
export default GenericErrorComponent;
